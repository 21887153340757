import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Icon from "../components/Icon"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import SEO from "../components/SEO"
import { SimplePage } from "../components/SimplePage"

const ContactCard = ({ title, subtitle, icon, link }) => (
  <Col md="6" className="text-center mb-4 mb-lg-0">
    <Card className="shadow-sm" body>
      <Icon icon={icon} size="2x" className="text-primary mb-4" />
      <h6 className="text-uppercase text-gray-700 mb-1">{subtitle}</h6>
      <a href={link} className="font-weight-bold text-dark h4">
        {title}
      </a>
    </Card>
  </Col>
)

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <PageHeader title="Contact" subtitle="Opening hours: Mon - Fri, 9am - 5pm" />
    <SimplePage>
      <Row>
        <ContactCard
          title="+49 176 209 566 86"
          subtitle="Call Us"
          icon="phone-alt"
          link="tel:+4917620956686"
        />
        <ContactCard
          title="support@lumis.ai"
          subtitle="Email us"
          icon="envelope"
          link="mailto:support@lumis.ai"
        />
      </Row>
    </SimplePage>
  </Layout>
)

export default Contact
