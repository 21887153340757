import React from "react"
import Container from "react-bootstrap/Container"
import ShapeCurve from "../../assets/svg/shapes/curves/curve-1.svg"

const PageHeader = ({ title, subtitle }) => (
  <section>
    <Container className="text-center text-lg-left pt-7 pt-lg-9 pb-8 pb-lg-10">
      <h1 className="display-4 mb-2">{title}</h1>
      {subtitle && <p className="font-size-lg text-secondary mb-0">{subtitle}</p>}
    </Container>
    <div className="position-relative">
      <div className="shape shape-bottom shape-fluid-x svg-shim" style={{ color: "#f6f8fb" }}>
        <ShapeCurve />
      </div>
    </div>
  </section>
)

export default PageHeader
